const removeDiacriticsAndSpecialChars = (fileName) => {
    const lookup = {
      'á': 'a', 'â': 'a', 'ã': 'a', 'à': 'a',
      'é': 'e', 'ê': 'e', 'í': 'i', 'ó': 'o',
      'ô': 'o', 'õ': 'o', 'ú': 'u', 'ü': 'u',
      'ç': 'c', 'ñ': 'n'
    };

    // eslint-disable-next-line
    const cleanText = fileName.replace(/[^\x00-\x7F]/g, c => lookup[c] || c)
      .replace(/[^\w\s.]/gi, '')
      .replace(/\s/g, '_');

    return cleanText;

}

export default removeDiacriticsAndSpecialChars;