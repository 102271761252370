import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Block = styled(Box)(({ isdragging }) => ({
    opacity: isdragging === 'true' ? '0.5' : '1',
    marginTop: '10px',
    width: '100%',
    height: 'fit-content', 
    minHeight: '6.5em', 
    display: 'flex',
}))

export const ItemDraggable = styled(Box)(() => ({
    display: 'flex',
    width: '3em',
    cursor: 'grab',
    justifyContent: 'center',
    alignItems: 'center',
}))

export const ContentBox = styled(Box)(() => ({
    display: 'flex', 
    width: '90%'
}))



