import { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { CustomTextarea } from '../../../../../styles/globals.styles';
import { Container, BlankBox, Image } from './VideoLinkDrag.styles';

const VideoLinkDrag = ({ getValue, name, savedValue }) => {
  const [value, setValue] = useState('');
  const [src, setSrc] = useState(null);

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result;
        setSrc(base64String);
      };

      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.gif'],
    },
  });

  useEffect(() => {
    getValue({
      [name]: {
        text: value,
        gif: src
      }
    })
  }, [getValue, value, src, name]);

  useEffect(() => {
    if (savedValue) {
      setValue(savedValue.text);
      setSrc(savedValue.gif);
    }
  }, [savedValue]);

  return (
    <>
      <Container {...getRootProps()}>
        <input {...getInputProps()} />

        {src ? (
          <Image src={src} alt='Dropped' name={name}></Image>
        ) : (
          <BlankBox> Clique ou arraste aqui a imagem/gif para o link </BlankBox>
        )}
      </Container>
      <CustomTextarea value={value} onChange={(e) => setValue(e.target.value)} />
    </>
  );
};


export default VideoLinkDrag;
