import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { Typography } from 'antd';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import AddIcon from '@mui/icons-material/Add';
import UploadContact from './UploadContact';
import InputFieldSpecial from './InputField';

const MAILER_URL = process.env.REACT_APP_MAILER_URL;

const AddContact = ({ getInfoContacts, disabled, call }) => {

  const [contactListName, setContactListName] = useState([]);
  const [contactListEmail, setContactListEmail] = useState([]);
  const [file, setFile] = useState();

  const handleContactNameChange = (index, value) => {
    const newContactListName = [...contactListName];
    newContactListName[index] = value;
    
    setContactListName(newContactListName);
  };

  const handleContactEmailChange = (index, value) => {
    const newContactListEmail = [...contactListEmail];
    newContactListEmail[index] = value;
    setContactListEmail(newContactListEmail);
  };

  useEffect(() => {
    getInfoContacts(contactListName, contactListEmail, file);
  }, [contactListEmail, contactListName, file, getInfoContacts]);

  const handleContactAdd = () => {
      setContactListName([...contactListName, '']);
      setContactListEmail([...contactListEmail, '']);
  };

  const getFile = async (data) => setFile(data);

  const handleDownload = async () => {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
      credentials: 'include'
    }

    const response = await fetch(`${ MAILER_URL }/contact/downloadFile`, options);

    const blob = await response.blob();

    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'modelo.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleContactDelete = (index) => {

    const newListName = contactListName.filter((_, key) => key !== index);
    setContactListName(newListName);
    const newListEmail = contactListEmail.filter((_, key) => key !== index);
    setContactListEmail(newListEmail);

  };

  return (
    <Box component='main' style={styles.container}>

      <Box component='main' style={styles.contentBox}>
        <Button disabled={disabled} style={styles.buttonAdd} onClick={handleContactAdd} variant='outlined' color='secondary' endIcon={<AddIcon />}>
          Adicionar Contato Manual
        </Button>

        {contactListName.map((_, index) => (
          <Box key={index} style={styles.contactField} >
            <Box style={styles.divisor}>

              <InputFieldSpecial
                call={call}
                type='name'
                label='Nome do Contato'
                defaultValue={contactListName[index]}
                name={'contactName' + index}
                onChange={(e, value) => handleContactNameChange(index, value)}  
              />

              <Button onClick={() => handleContactDelete(index)} style={styles.buttonDelete} variant='outlined' color='error'>
                Remover
              </Button>
            </Box>

              <InputFieldSpecial
                call={call}
                type='email'
                label='Email'
                defaultValue={contactListEmail[index]}
                name={'contactEmail' + index}
                onChange={(_, value) => handleContactEmailChange(index, value)}
              />
          </Box>
        ))}

        <Box>
          <Typography style={styles.h5}>Atenção! É importante lembrar que para importar contatos deve-se utilizar a formatação de excel a seguir:</Typography>
          <Button
            disabled={disabled}
            onClick={handleDownload}
            style={styles.buttonDownload}
            variant='contained'
            size='small'
            color='secondary'
            startIcon={<FileDownloadOutlinedIcon />} >
            Download
          </Button>
        </Box>

        <Box style={styles.uploadBox}>
          <UploadContact disabled={disabled} getFile={getFile} />
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  container: {
    width: '100%',
    margin: '4% 0'
  },

  title: {
    color: '#008747',
    fontSize: '30px',
    margin: '0 auto',
    fontWeight: '500',
    padding: '0 10px'
  },

  contentBox: {
    margin: '3% 0',
  },

  buttonAdd: {
    width: '100%',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    borderWidth: '2px',
    textTransform: 'none'
  },

  contactField: {
    margin: '12px 0',
    padding: '10px',
    borderRadius: '15px',
    width: '100%'
  },

  divisor: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '12px',
  },

  buttonDelete: {
    width: '22%',
    borderRadius: '10px',
    display: 'flex',
    textTransform: 'none',
    marginLeft: '5px'
  },

  h5: {
    display: 'flex',
    fontSize: '16px',
    width: '100%',
    marginTop: '2em',
    lineHeight: '1em',
    color: '#008747'
  },

  buttonDownload: {
    float: 'right',
    display: 'flex',
    margin: '10px 0',
    borderRadius: '7px',
    textTransform: 'none'

  },

  uploadBox: {
    margin: '4.5em 0 1em 0',
  },
}

export default AddContact;