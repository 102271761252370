import { useEffect, useState } from 'react';
import { Box, FormHelperText } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { MultipleSelect, ButtonSend, InputField } from '../../../../components'

const MAILER_URL = process.env.REACT_APP_MAILER_URL;

const CreateCompany = () => {
  const [english, setEnglish] = useState(false);
  const [portuguese, setPortuguese] = useState(false);
  const [error, setError] = useState(false);
  const [call, setCall] = useState(false);
  const [selectValue, setSelectValue] = useState(false);
  const [formData, setFormData] = useState(new FormData());

  useEffect(() => {

    if (portuguese || english) setError(false);

  }, [portuguese, english]);

  const getSelectValue = async (data) => setSelectValue(data);

  const verifyFields = (form) => {

    const data = new FormData(form);

    setFormData(data);

    setCall(!call);

    data.append('portuguese', portuguese);
    data.append('english', english);
    data.append('groups', selectValue.map((item) => item.id_send_group).join(','));

    data.set('nameCompany', data.get('nameCompany').trim());

    if (!portuguese && !english) {
      setError(true);
      return;
    } else setError(false);

    for (const [, value] of data.entries()) if (value === '') return;

    return true;

  };

  const handleSubmit = async () => {

    const options = {
      method: 'POST',
      body: JSON.stringify(Object.fromEntries(formData)),
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' }
    }

    const response = await fetch(`${ MAILER_URL }/company/create`, options);

    const responseBody = await response.json();
    const message = responseBody.message;

    if (response.status === 201) return;
    else return message;

  };

  return (
    <Box style={styles.container}>

      <Box component='form' autoComplete='off'>

        <fieldset style={styles.fieldset}>
          <legend style={styles.title}>Informações da Empresa</legend>

          <Box style={styles.contentBox}>

            <Box style={styles.divisor}>
              <InputField 
                label='Nome da Empresa' 
                name='nameCompany' 
                type='name' 
                call={call} 
                />
            </Box>

            <MultipleSelect 
              getSelectValue={getSelectValue} 
              call={call} 
              URL={`${ MAILER_URL }/group/getAll`} 
              reference='send_group' 
            />

            <Box style={styles.companyLanguageBox}>
              <p>Esta empresa aceita idiomas em: </p>
              <FormGroup style={styles.checkBox}>
                <FormControlLabel control={<Checkbox onChange={() => setPortuguese(!portuguese)} />} label='Português' />
                <FormControlLabel control={<Checkbox onChange={() => setEnglish(!english)} />} label='Inglês' />

              </FormGroup>
              <FormHelperText style={{ color: 'red' }}>{error && 'Pelo menos um idioma deve estar selecionado!'}</FormHelperText>
            </Box>

            <Box style={styles.buttonBox}>
              <ButtonSend
                handleSubmit={handleSubmit}
                verifyFields={verifyFields}
                text='Criar Empresa'
                description='Tem certeza de que deseja criar esta empresa?'
                descriptionSuccess='Empresa criada com sucesso!'
              />
            </Box>
          </Box>
        </fieldset>

      </Box>

    </Box>
  );

}

const styles = {
  container: {
    width: '50%',
  },

  fieldset: {
    borderRadius: '15px',
    border: 'solid',
    borderWidth: '3px',
    borderColor: '#202956',
    height: 'fit-content',
  },

  title: {
    color: '#202956',
    fontSize: '30px',
    margin: '0 auto',
    fontWeight: '500',
    padding: '0 10px'
  },

  contentBox: {
    padding: '6% 4% 4% 4%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  divisor: {
    marginBottom: '15px',
  },

  companyLanguageBox: {
    marginTop: '4%',
  },

  checkBox: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '2%',
  },

  buttonBox: {
    padding: '4% 4% 0 4%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
  },

}

export default CreateCompany;