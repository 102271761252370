import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import SelectToFill from './SelectToFill/SelectToFill'

import { MultipleGroupSelect, ButtonSend, ButtonDelete, InputField } from '../../../../../components';

const WHATSAPP_URL = process.env.REACT_APP_WHATSAPP_URL;

const EditWhatsappGroup = () => {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [newSelectedGroupsList, setNewSelectedGroupsList] = useState();
  const [selectedWhatsappGroup, setSelectedWhatsappGroup] = useState();
  const [newWhatsappGroupName, setNewWhatsappGroupName] = useState();
  const [updatedGroups, setUpdatedGroups] = useState();
  const [call, setCall] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (selectedWhatsappGroup) setDisabled(false);
    else setDisabled(true);
  }, [selectedWhatsappGroup]);

  const getSelectValue = async (data) => setNewSelectedGroupsList(data);

  const getSelectedValue = async (data) => setSelectedWhatsappGroup(data);

  useEffect(() => {

    if (!selectedWhatsappGroup) return;

    (async () => {
      const options = {

        method: 'POST',
        body: JSON.stringify({ selectedWhatsappGroup }),
        headers: {
          'Content-type': 'application/json',
        },
        credentials: 'include',

      };
      const response = await fetch(`${ WHATSAPP_URL }/whatsappGroup/getOne`, options);

      const message = await response.json();

      setSelectedGroups(message.sendGroupWhatsapp);

    })();

  }, [selectedWhatsappGroup]);

  const verifyFields = (form) => {

    const formData = new FormData(form);

    setCall(!call);

    setNewWhatsappGroupName(formData.get('newWhatsappGroupName'));
    setUpdatedGroups(newSelectedGroupsList || selectedGroups);

    formData.append('groups', newSelectedGroupsList || selectedGroups);
    formData.append('company', selectedWhatsappGroup);

    if (!formData.get('groups')) return;

    for (const [, value] of formData.entries()) {
      if (value === '') return;
    }

    return true;
  }

  const handleSubmit = async () => {

    const options = {
      method: 'PATCH',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({
        whatsappGroup: selectedWhatsappGroup,
        newWhatsappGroupName: newWhatsappGroupName,
        groups: updatedGroups,
      }),
      credentials: 'include'
    };

    const response = await fetch(`${ WHATSAPP_URL }/whatsappGroup/update`, options);

    const responseBody = await response.json();
    const message = responseBody.message;

    if (response.status === 200) return;
    else return message;

  };

  const handleDelete = async () => {

    const options = {
      method: 'DELETE',
      credentials: 'include',
    }

    const response = await fetch(`${ WHATSAPP_URL }/whatsappgroup/delete/${selectedWhatsappGroup}`, options);

    if (response.status === 204) return;
    else return 'Falha ao deletar o grupo de whatsapp';

  };

  return (
    <Box component='main' style={styles.container}>

      <fieldset style={styles.fieldset}>

        <legend style={styles.title}>Editar Grupo de Whatsapp</legend>

        <Box component='form' autoComplete='off' onSubmit={handleSubmit} style={styles.contentBox}>
          <Box style={styles.companyNameBox}>
            <SelectToFill label='Grupo de Whatsapp' getSelectedValue={getSelectedValue} call={call} />

            <Box style={styles.divisor}>
              <InputField
                textColor='#3472BD'
                label='Nome do Grupo de Whatsapp'
                type='group'
                name='newWhatsappGroupName'
                defaultValue={selectedWhatsappGroup}
                call={call}
                disabled={disabled}
              />
            </Box>
          </Box>

          <MultipleGroupSelect 
            disabled={disabled} 
            value={selectedGroups} 
            getSelectValue={getSelectValue} 
            call={call} 
            URL={`${ WHATSAPP_URL }/sendGroup/getAll`} 
            nameProps='nm_send_group_whatsapp' 
          />

          <Box style={styles.buttonBox}>
            <Box style={styles.buttonDelete}>
              <ButtonDelete
                text='Deletar Grupo de Whatsapp'
                handleDelete={handleDelete}
                description='Tem certeza de que deseja deletar este grupo de whatsapp?'
                descriptionSuccess='Grupo de whatsapp deletado com sucesso!'
              />
            </Box>

            <Box style={styles.buttonSubmit}>
              <ButtonSend
                text='Atualizar Grupo de Whatsapp'
                verifyFields={verifyFields}
                handleSubmit={handleSubmit}
                description='Tem certeza de que deseja atualizar este grupo de whatsapp?'
                descriptionSuccess='Grupo de Whatsapp atualizado com sucesso!'
              />
            </Box>
          </Box>

        </Box>
      </fieldset>

    </Box>
  );
};

const styles = {
  container: {
    width: '50%',
  },

  fieldset: {
    borderRadius: '15px',
    border: 'solid',
    borderWidth: '3px',
    borderColor: '#202956',
    paddingTop: '2%',
    height: 'fit-content',
    marginBottom: '5%',
  },

  title: {
    color: '#202956',
    fontSize: '30px',
    margin: '0 auto',
    fontWeight: '500',
    padding: '0 10px'
  },

  contentBox: {
    padding: '3% 4% 0 4%',
  },

  companyNameBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px'
  },

  divisor: {
    marginLeft: '15px',
    width: '100%',
  },

  buttonBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '5%',
    marginBottom: '5%',
  },

};

export default EditWhatsappGroup;