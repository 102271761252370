import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

const AGROAUTH_URL = process.env.REACT_APP_AGROAUTH_URL;
const ID_SYSTEM = process.env.REACT_APP_ID_SYSTEM;

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const login = async (login, password) => {

    setLoading(true);

    const options = {

      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ login, password, idSystem: ID_SYSTEM }),
      credentials: 'include'

    };

    try {
      
      const response = await fetch(`${AGROAUTH_URL}/login`, options);

      setLoading(false);

      if (response.status === 200) {

        setError(false);
        navigate('/mail');

      } else setError(true);

    } catch (err) {
      console.error(err);
    }

    setLoading(false);

  }

  const logout = async () => {

    const options = {

      method: 'GET',
      credentials: 'include',

    };

    await fetch(`${AGROAUTH_URL}/logout`, options);

    navigate('/login');

  }

  return (
    <AuthContext.Provider value={{ login, logout, loading, error }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider;