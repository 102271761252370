import { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import ReactQuill from 'react-quill';

const toolbarOptions = [
  ['bold', 'italic', 'underline'], 
  [{ align: [] }],
  ['link'], 
];

const TextareaDrag = ({ getValue, name, savedValue}) => {
  const [value, setValue] = useState('');
  const quillRef = useRef();

  const handleValue = (content) => setValue(content);
  
  useEffect(() => {
    getValue({
      [name]: value
    })
  }, [getValue, value, name]);

  useEffect(() => {
    if (savedValue) setValue(savedValue);
  }, [savedValue]);

  return (
    <Box width={'100%'} zIndex={1}>
      <ReactQuill 
        ref={quillRef}
        value={value} 
        onChange={(value) => handleValue(value)}
        style={{ width: '100%' }}
        modules={{
          toolbar: toolbarOptions,
        }}
      />
    </Box>
  );
};

export default TextareaDrag;