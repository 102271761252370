import { styled } from '@mui/system';

export const CustomTextarea = styled('textarea')(({ theme }) => ({
    fontSize: '18px',
    resize: 'none',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    padding: '5px 0 0 5px',
    borderColor: theme.palette.primary.main,
    '&:focus': {
        outline: 'none',
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
}));