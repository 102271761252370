import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, BottomNavigation, BottomNavigationAction, ListItemText, ListItemIcon, ListItem, MenuItem, Menu } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import AgroconsultLogo from '../../assets/agroconsult.svg';
import { AuthContext } from '../../context/auth';

const options = [
  'Gerenciar Emails',
  'Gerenciar Whatsapp',
];

const MenuTest = ({ tabState, indexSelected }) => {
  const [value, setValue] = useState(tabState);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(indexSelected);
  
  const navigate = useNavigate();
  const { logout } = useContext(AuthContext);

  const handleChange = (_, newValue) => setValue(newValue);
  
  const open = Boolean(anchorEl);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (_, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);

    if (index === 0) navigate(`/management/emails`);
    else if (index === 1) navigate('/management/whatsapp');

  };

  const handleClose = () => setAnchorEl(null);

  return (
    <Box style={styles.container}>
      <Box style={styles.divisorLogo}>
        <img src={AgroconsultLogo} style={styles.logo} alt='' onClick={() => navigate('/mail')} />
      </Box>

      <Box style={styles.boxNavigation}>
        <BottomNavigation color='secondary' value={value} onChange={handleChange} style={styles.bottomNavigation}>
          <BottomNavigationAction
            label='Emails'
            value='emails'
            color='secondary'
            onClick={() => navigate('/mail')}
            icon={<MailOutlineIcon color='secondary' />}
            sx={{
              margin: 0,
              '& .MuiBottomNavigationAction-label': {
                color: 'rgb(0, 0, 0, 0.6)',
                paddingTop: '8px',
              }
            }}
          />
        </BottomNavigation>

        <BottomNavigation color='secondary' value={value} onChange={handleChange} style={styles.bottomNavigation}>
          <BottomNavigationAction
            label='Whatsapp'
            value='whatsapp'
            color='secondary'
            onClick={() => navigate('/whats')}
            icon={<WhatsAppIcon color='secondary' />}
            sx={{
              margin: 0,
              '& .MuiBottomNavigationAction-label': {
                color: 'rgb(0, 0, 0, 0.6)',
                paddingTop: '8px',
              }
            }}
          />
        </BottomNavigation>

        <ListItem
          onClick={handleClickListItem}
          style={styles.listItem}
        >
          <ListItemIcon style={styles.listItemIcon}>
            <SettingsOutlinedIcon color='secondary' />
          </ListItemIcon>

          <ListItemText
            secondary={options[selectedIndex]}
            align='center'
            style={styles.listItemText}
          />
        </ListItem>

        <Menu
          id='lock-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          autoFocus={false}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 3,
              
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 25,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={option}
              selected={index === selectedIndex}
              onClick={(event) => handleMenuItemClick(event, index)}
            >
              {option}
            </MenuItem>
          ))}
        </Menu>

        <BottomNavigation color='secondary' value={value} onChange={handleChange} style={styles.bottomNavigation}>

          <BottomNavigationAction
            label='Sair'
            value='sair'
            onClick={logout}
            icon={<LogoutOutlinedIcon color='secondary' />}
            sx={{
              margin: 0,
              '& .MuiBottomNavigationAction-label': {
                color: 'rgb(0, 0, 0, 0.6)',
                paddingTop: '8px'
              }
            }}
          />
        </BottomNavigation>
      </Box>
    </Box>
  );
}

const styles = {

  container: {
    display: 'flex',
    width: '100%',
    height: '7em',
    backgroundColor: '#f7f7f7',
    justifyContent: 'space-between',
    alignItems: 'end',
  },

  divisorLogo: {
    height: 'fit-contet',
    float: 'start',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '60px',
    paddingTop: '1em',
    cursor: 'pointer',
  },

  title: {
    color: '#1B286A',
    display: 'flex',
    fontSize: '40px',
    letterSpacing: '5px',
    fontWeight: '900',
    textAlign: 'start',
    justifyContent: 'start',
    width: 'fit-contet',
    height: 'fit-contet',
    cursor: 'pointer'
  },

  subtitleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'fit-content',
    width: 'fit-contet',
  },

  logo: {
    height: '6.5em',
    display: 'flex',
  },

  boxNavigation: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '30%',
    height: '50px',
    marginRight: '4%'
  },

  bottomNavigation: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    height: '100%',
    backgroundColor: '#f7f7f7',
    float: 'right'
  },

  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    padding: 0,
    cursor: 'pointer',
    height: 'fit-content'
  },
  
    listItemIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },

    listItemText: {
    width: 'max-content',
    display: 'flex',
    flexWrap: 'nowrap'
  }

}

export default MenuTest;