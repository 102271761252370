import { useState, useEffect } from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { Menu } from '../../../components';
import EditCompany from './EditCompany';
import CreateCompany from './CreateCompany';
import Groups from './Groups';

const TabsWrappedLabel = ({ getSelectedTab }) => {
  const [value, setValue] = useState('editar');

  useEffect(() => {
    getSelectedTab(value)
  }, [getSelectedTab, value])

  return (
    <Box style={styles.tabContaneir}>
      <Tabs
        value={value}
        onChange={(_, value) => setValue(value)}
        aria-label='wrapped label tabs example'
      >
        <Tab value='editar' label='Editar Empresa' />
        <Tab value='criar' label='Criar Empresa' />
        <Tab value='grupo' label='Grupos de Envio' />
      </Tabs>
    </Box>
  );
}

const EmailsConfig = () => {
  const [selectedTab, setSelectedTab] = useState();

  const getSelectedTab = (data) => setSelectedTab(data);

  return (

    <Box style={styles.container}>
      <Menu tabState='' indexSelected='0' />
      <Box style={styles.contentBox}>

        <TabsWrappedLabel getSelectedTab={getSelectedTab} />
        {selectedTab === 'editar' && <EditCompany />}
        {selectedTab === 'criar' && <CreateCompany />}
        {selectedTab === 'grupo' && <Groups />}

      </Box>
    </Box>

  );
}

const styles = {
  tabContaneir: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '2em',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    minWidth: '100vw',
  },

  contentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%',
    marginTop: '2%'
  },
}

export default EmailsConfig;