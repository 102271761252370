import { useState, useEffect } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Box, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const WHATSAPP_URL = process.env.REACT_APP_WHATSAPP_URL;

const SelectToFill = ({ label, getSelectedValue, call}) => {
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [error, setError] = useState(false);

  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const errorColor = theme.palette.error.main;

  useEffect(() => {
    getSelectedValue(selectedCompany);
  }, [getSelectedValue, selectedCompany]);

  useEffect(() => {

    if (call && !selectedCompany) setError(true);
    
  }, [call, selectedCompany]);

  useEffect(() => {
    (async () => {
      const options = {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        },
        credentials: 'include',
      }

      const response = await (await fetch(`${ WHATSAPP_URL }/whatsappGroup/getAll`, options)).json();
      
      const companies = response.groups.map((group) => ({
        id: group.id_whatsapp_group,
        value: group.nm_whatsapp_group,
        label: group.nm_whatsapp_group,
      }));

      setCompanies(companies);
    })();
  }, []);

  return (
    <Box>
      <FormControl sx={{ width: '100%', minWidth: 250 }}>
        <InputLabel 
          size='small' 
          id='demo-simple-select-helper-label'
          sx={{
            color: error ? errorColor : primaryColor,
            '&.Mui-focused': {
              color: primaryColor,
            },
          }}
        > 
          {label} 
        </InputLabel>
        <Select
          sx={{
            borderRadius: '10px',
            height: '40px',
            color: '#3472BD',
            backgroundColor:'rgb(52, 114, 189, 0.1)',
            '&.MuiInputBase-formControl': {
              width: '100%'
            },
            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: error ? errorColor : primaryColor,
                border: '2px solid rgb(52, 114, 189, 0.7)',
              },
              '&:hover fieldset': {
                borderColor: primaryColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: primaryColor,
              },
            },
            '.MuiSvgIcon-root ': {
              fill: '#3472BD !important',
            }
          }}
          inputProps={{
            MenuProps: {
              MenuListProps: {
                sx: {
                  overflowY: 'auto',
                  maxHeight: '20em',
                  color: primaryColor,
                }
              }
            }
          }}
          value={selectedCompany}
          color='primary'
          onChange={(e) => {
            if (!e.target.value) setError(true);
            else setError(false);
            setSelectedCompany(e.target.value)}
          } 
          input={<OutlinedInput label={label} />}
        >
          {companies.map((company) => (
            <MenuItem key={company.id} value={company.value}>
              {company.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SelectToFill;