import { useState } from 'react';
import { Box } from '@mui/material';

import { MultipleGroupSelect, ButtonSend, InputField } from '../../../../../components';

const WHATSAPP_URL = process.env.REACT_APP_WHATSAPP_URL;

const CreateCompany = () => {
  const [call, setCall] = useState(false);
  const [selectValue, setSelectValue] = useState(false);
  const [nameGroup, setNameGroup] = useState('');

  const getSelectValue = async (data) => setSelectValue(data);

  const verifyFields = (form) => {
    const data = new FormData(form);

    setCall(!call);

    data.append('groups', selectValue);

    setNameGroup(data.get('nameGroup').trim());

    data.set('nameGroup', data.get('nameGroup').trim());

    for (const [, value] of data.entries()) if (value === '') return;

    return true;

  };

  const handleSubmit = async () => {

    const options = {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
      },
      body: JSON.stringify({nameGroup: nameGroup, groups: selectValue}),
      credentials: 'include'
    }

    const response = await fetch(`${ WHATSAPP_URL }/whatsappGroup/create`, options);

    const responseBody = await response.json();
    const message = responseBody.message;

    if (response.status === 201) return;
    else return message;

  };

  return (
    <Box style={styles.container}>

      <Box component='form' autoComplete='off'>

        <fieldset style={styles.fieldset}>
          <legend style={styles.title}>Criar Grupo de Whatsapp</legend>

          <Box style={styles.contentBox}>

              <InputField 
                label='Nome do Grupo de Whatsapp' 
                name='nameGroup' 
                type='group' 
                call={call} 
                />

            <Box style={styles.divisor}>
              <MultipleGroupSelect getSelectValue={getSelectValue} call={call} URL={`${ WHATSAPP_URL }/sendGroup/getAll`} nameProps='nm_send_group_whatsapp'/>
            </Box>

            <Box style={styles.buttonBox}>
              <ButtonSend
                handleSubmit={handleSubmit}
                verifyFields={verifyFields}
                text='Criar Grupo de Whatsapp'
                description='Tem certeza de que deseja criar este grupo de whatsapp?'
                descriptionSuccess='Grupo de whatsapp criado com sucesso!'
              />
            </Box>
          </Box>
        </fieldset>

      </Box>

    </Box>
  );
}

const styles = {
  container: {
    width: '50%',
    marginBottom: '30px',
  },

  fieldset: {
    borderRadius: '15px',
    border: 'solid',
    borderWidth: '3px',
    borderColor: '#202956',
    height: 'fit-content',
  },

  title: {
    color: '#202956',
    fontSize: '30px',
    margin: '0 auto',
    fontWeight: '500',
    padding: '0 10px'
  },

  contentBox: {
    padding: '6% 4% 4% 4%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  divisor: {
    marginTop: '20px',
  },

  buttonBox: {
    padding: '5% 4% 0 4%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
  },

}

export default CreateCompany;