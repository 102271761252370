import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { Select, InputField, ButtonDelete, ButtonSend } from '../../../../../components';

const WHATSAPP_URL = process.env.REACT_APP_WHATSAPP_URL;

const EditGroup = () => {
  const [selectedGroup, setSelectedGroup] = useState();
  const [groupEdited, setGroupEdited] = useState(selectedGroup);
  const [call, setCall] = useState(false);

  const getSelectedGroup = async (data) => setSelectedGroup(data);

  useEffect(() => setGroupEdited(selectedGroup), [selectedGroup]);

  const verifyFields = (form) => {
    const data = new FormData(form);

    setCall(!call);

    setGroupEdited(data.get('group'));

    for (const [, value] of data.entries()) if (value === '') return;

    return true;

  }

  const handleSubmit = async () => {

    const groupData = {
      group: groupEdited.trim(),
      selectedGroup: selectedGroup
    };

    for (let key in groupData) if (!groupData[key]) return false;

    const options = {
      method: 'PATCH',
      body: JSON.stringify(groupData),
      headers: {
        'Content-type': 'application/json'
      },
      credentials: 'include'
    }

    const response = await fetch(`${ WHATSAPP_URL }/sendGroup/update`, options);

    const reponseBody = await response.json();
    const message = reponseBody.message;

    if (response.status === 200) return;
    else return message;

  };

  const handleDelete = async () => {

    const options = {
      method: 'DELETE',
      credentials: 'include',
    }

    const response = await fetch(`${ WHATSAPP_URL }/sendGroup/delete/${selectedGroup}`, options);

    if (response.status === 204) return;
    else return 'Falha ao deletar o grupo';

  };

  return (
    <Box component='main' style={styles.container}>

      <fieldset style={styles.fieldset}>
        <legend style={styles.title}>Editar Grupo de Envio</legend>

        <Box component='form' style={styles.contentBox} >

          <Box style={styles.divisor}>

            <InputField
              label='Nome do Grupo de Envio'
              name='group'
              type='group'
              defaultValue={groupEdited}
            />

          </Box>

          <Select getSelectedGroup={getSelectedGroup} nameVariable='nm_send_group_whatsapp' URL={`${ WHATSAPP_URL }/sendGroup/getAll`} />

          <Box style={styles.buttonBox}>

            <ButtonDelete
              text='Deletar Grupo de Envio'
              handleDelete={handleDelete}
              description='Tem certeza de que deseja deletar este grupo?'
              descriptionSuccess='Grupo deletado com sucesso!'
            />

            <ButtonSend
              verifyFields={verifyFields}
              value={groupEdited}
              handleSubmit={handleSubmit}
              text='Editar Grupo de Envio'
              description={`Tem certeza de que deseja editar o grupo ${selectedGroup}?`}
              descriptionSuccess={`O grupo ${selectedGroup} foi atualizado com sucesso para ${groupEdited}!`}
            />

          </Box>
        </Box>
      </fieldset>
    </Box>
  );
}

const styles = {
  container: {
    width: '50%',
    marginTop: '2em'
  },

  fieldset: {
    borderRadius: '15px',
    border: 'solid',
    borderWidth: '3px',
    borderColor: '#202956',
    height: 'fit-content',
  },

  title: {
    color: '#202956',
    fontSize: '30px',
    margin: '0 auto',
    fontWeight: '500',
    padding: '0 10px'
  },

  contentBox: {
    padding: '6% 4% 4% 4%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  divisor: {
    marginBottom: '15px',
  },

  buttonBox: {
    padding: '4% 4% 0 4%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
  },


}

export default EditGroup;