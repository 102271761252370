import Box from '@mui/material/Box';
import EditWhatsappGroup from './EditWhatsappGroup';
import CreateWhatsappGroup from './CreateWhatsappGroup';

const WhatsappGroups = () => {

  return (
    <Box style={styles.container}>
      <Box style={styles.contentBox} >
        <CreateWhatsappGroup />
        <EditWhatsappGroup />
      </Box>
    </Box>
  );
  
}

const styles = {

  container: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  contentBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100vw',
  },

}

export default WhatsappGroups;