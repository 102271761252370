import { useState, useEffect } from 'react';
import { Popconfirm } from 'antd';
import { Box, Button } from '@mui/material';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { CheckCircleOutlined } from '@ant-design/icons';

const ButtonSend = ({ verifyFields, handleSubmit, text, description, descriptionSuccess, selectedGroup, disabled }) => {
  const [titleMessage, setTitleMessage] = useState('Atenção!');
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [requestFinished, setRequestFinished] = useState(false);
  const [successResponse, setSuccessResponse] = useState(false);
  const [descriptionPop, setDescriptionPop] = useState(description);
  const [failed, setFailed] = useState(false);

  const showPopconfirm = async (e) => {
    const valid = await verifyFields(e.currentTarget.form);

    if (valid) setOpen(true);
  };

  useEffect(() => {
    setDescriptionPop(description)
  }, [selectedGroup, description]);

  const handleOk = () => {
    setOpen(false);

    if (successResponse) window.location.reload();
    else {
      setTimeout(() => {
        setFailed(false);
        setRequestFinished(false);
        setDescriptionPop(description);
        setTitleMessage('Atenção!');
      }, 500);
    }
  };

  const handleCancel = () => setOpen(false);

  const handleRequest = async () => {
    setConfirmLoading(true);
    const requestMessage = await handleSubmit();
    setConfirmLoading(false);

    if (requestMessage) {
      setFailed(true);
      setDescriptionPop(requestMessage);
      setTitleMessage('Erro!');
    } else {
      setDescriptionPop(descriptionSuccess);
      setSuccessResponse(true);
      setTitleMessage('Sucesso!');
    };
    setRequestFinished(true);
  }

  return (
    <>
      {open && <Box style={styles.overlay} />}
      <Popconfirm
        cancelText='Cancelar'
        icon={successResponse ? <CheckCircleOutlined style={{ color: 'darkgreen' }} /> : <QuestionCircleOutlined />}
        okText={failed ? 'Ok' : 'Confirmar'}
        title={titleMessage}
        description={descriptionPop}
        open={open}
        onConfirm={requestFinished ? handleOk : handleRequest}
        okButtonProps={{
          loading: confirmLoading,
        }}
        onCancel={handleCancel}
        cancelButtonProps={{
          disabled: confirmLoading || requestFinished ? true : false,
        }}
      >
        <Button style={styles.button} color='secondary' variant='contained' onClick={showPopconfirm} disabled={disabled}>{text}</Button>
      </Popconfirm>
    </>
  )
};

const styles = {
  button: {
    width: 'fit-content',
    height: '35px',
    borderRadius: '9px',
    textTransform: 'none',
    opacity: '0.8',
  },

  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'black',
    opacity: 0.4,
    zIndex: 999,
  },
};

export default ButtonSend;