const CustomToolbar = () => {

  return (
    <div id='toolbar' style={styles.container}>
      <span className='ql-formats' style={styles.labelContainer}>
        <p> </p>
      </span>
    </div>
  )
};

const styles ={
  buttonCheckboxCode: {
    display: 'flex', 
    width: 'fit-content', 
    alignItems: 'center',
    justifyContent: 'center',
  },

  labelContainer: {
    display: 'flex',
    justifyContent: 'center',
    color: '#3472BD',
    minHeight: '3px'
  }
}


export default CustomToolbar;