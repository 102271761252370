import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthProvider from './context/auth';
import PrivateRoutes from './service/PrivateRoutes';
import Login from './pages/Login';
import Mail from './pages/Mail';
import Emails from './pages/Management/EmailsConfig';
import Whatsapp from './pages/Management/WhatsappConfig';
import Error404 from './pages/Error/Error404';
import WhatsappMessage from './pages/Whatsapp';

import '@fontsource/comfortaa';

const theme = createTheme({

  palette: {

    primary: {
      main: '#3472BD',
      light: '#6799D5',
      dark: '#1B286A'
    },

    secondary: {
      main: '#008747',
      light: '#08C46B',
      dark: '#029851'
    },

    custom: {
      light: '#fff',
      main: '#fff',
      dark: '#EAEDEF',
    },

    textColor: {
      main: '#575757',
      light: '#575757',
      dark: '#575757',
    },

    error: {
      main: '#D44E5A',
    },

  },

  typography: {
    fontFamily: [
      'Comfortaa'
    ].join(','),
  }
});

const App = () => {

  return (

    <Box style={styles.container}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Router>
          <AuthProvider>
            <Routes>
              <Route path='/login' element={<Login />} />
              <Route element={<PrivateRoutes />}>
                <Route exact path='/' element={<Mail />} />
                <Route path='/mail' element={<Mail />} />
                <Route path='/management/emails' element={<Emails />} />
                <Route path='/management/whatsapp' element={<Whatsapp />} />
                <Route path='/whats' element={<WhatsappMessage />} />
              </Route>
              <Route path='*' element={<Error404 />} />
            </Routes>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </Box>

  );
}

const styles = {
  container: {
    backgroundColor: '#f7f7f7',
    width: '100%',
    height: '100%',
    minHeight: '100vh',
    overflowX: 'hidden'
  }
}

export default App;