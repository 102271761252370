import { useEffect, useState, useRef } from 'react';
import { List, Divider, ListItemIcon, ListItemText, ListItemButton, ListItem } from '@mui/material';
import TitleIcon from '@mui/icons-material/Title';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import ShortTextIcon from '@mui/icons-material/ShortText';
import BurstModeIcon from '@mui/icons-material/BurstMode';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import ImageIcon from '@mui/icons-material/Image';
import DraggableArea from './DraggableArea';
import ImageDrag from './ImageDrag';
import TextareaDrag from './TextareaDrag';
import TitleDrag from './TitleDrag';
import VideoLinkDrag from './VideoLinkDrag';
import FooterDrag from './FooterDrag';

import { Drawer, StyledBox, Container, RotationArrow, WrapperBox } from './CustomTemplate.styles';

const CustomTemplate = ({ getCustomContentValue, filesNames }) => {
  const [open, setOpen] = useState(false);
  const [allBlocks, setAllBlocks] = useState([]);
  const [content, setContent] = useState([]);
  const [dynamicContent, setDynamicContent] = useState([{
    main: '',
    coverImage: '',
  }]);
  const [isRotated, setIsRotated] = useState(false);

  useEffect(() => {
    getCustomContentValue(dynamicContent);
  }, [getCustomContentValue, dynamicContent]);

  const toggleDrawer = () => {
    setIsRotated(!isRotated);
    setOpen(!open)
  };

  const dynamicStyles = {
    listItem: {
      minWidth: 0,
      mr: open ? 3 : 'auto',
      justifyContent: 'center',
    },

    iconButton: {
      minHeight: 48,
      justifyContent: open ? 'initial' : 'center',
      px: 2.5,
    },

    iconText: {
      opacity: open ? 1 : 0
    },
  };

  const updateAllBlocks = (data) => setAllBlocks(data);

  const getValue = (data) => {
    setContent((prevContent) => ({
      ...prevContent,
      ...data
    }));
  };

  const getOrder = (data) => {

    const reorganizedObject = data?.reduce((acc, key) => {
      acc[key] = content[key];
      return acc;
    }, {});

    const saveContentAvoidGifStorage = data?.reduce((acc, key) => {
      if (key.includes('video') && content[key]) {
        acc[key] = {
          "text": content[key]['text'],
          "gif": null,
        }
      } else acc[key] = content[key];
      return acc;
    }, {});

    const allContent = {
      main: '',
      coverImg: '',
      footer: '',
    };

    for (const [key, value] of Object.entries(reorganizedObject)) {
      if (key.includes('title')) allContent['main'] = allContent['main'] + `<table class="spacing-element"></table><h2>${value}</h2>`;
      if (key.includes('image')) allContent['main'] = allContent['main'] + `<img src="${value}" alt="" width="600" />`;
      if (key.includes('text')) allContent['main'] = allContent['main'] + `<p>${value}</p>`;
      if (key.includes('video')) {
        allContent['main'] = allContent['main'] + `<a href="${value?.text}" target="_blank"> <img class="gif" src="${value?.gif}" alt="Video Thumbnail" width="600"> </a>`;
      }
      if (key.includes('coverImg')) allContent['coverImg'] = allContent['coverImg'] + `<img src="${value}" alt="" width="600" />`;
      if (key.includes('footerText')) allContent['footer'] = allContent['footer'] + `<p>${value}</p>`;
      if (key.includes('footerImg')) allContent['footer'] = allContent['footer'] + `<img src="${value}" alt="" width="40%" class="img-footer" /> <table class="border-element"></table>`;
    }

    if (!Array.isArray(content)) localStorage.setItem('customContent', JSON.stringify(saveContentAvoidGifStorage));

    setDynamicContent(prevState => {
      const isEqual = JSON.stringify(prevState) === JSON.stringify(allContent);
      if (!isEqual) return allContent;
      else return prevState;
    });

  };

  const incrementBlock = async (type) => {

    const updatedBlocks = [...allBlocks];
    const id = updatedBlocks.length + 1;
    let content;

    switch (type) {
      case 'title': content = <TitleDrag getValue={getValue} name={`${id}_${type}`} />;
        break
      case 'text': content = <TextareaDrag getValue={getValue} name={`${id}_${type}`} />;
        break
      case 'image': content = <ImageDrag getValue={getValue} name={`${id}_${type}`} />;
        break
      case 'video': content = <VideoLinkDrag getValue={getValue} name={`${id}_${type}`} />;
        break
      case 'coverImg': content = <ImageDrag getValue={getValue} name={`${id}_${type}`} />;
        break
      case 'footerText': content = <FooterDrag getValue={getValue} name={`${id}_${type}`} />;
        break
      case 'footerImg': content = <ImageDrag getValue={getValue} name={`${id}_${type}`} />;
        break
      default: break;
    };

    updatedBlocks.push({ id: `${id}_${type}`, content: content });
    await setAllBlocks(updatedBlocks);

    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });

  };

  const messagesEndRef = useRef(null);

  return (

    <Container>
      <WrapperBox style={{backgroundColor: 'white', borderRadius: '10px'}}>
        <Drawer variant="permanent" open={open}>
          <List disablePadding>
            <ListItemButton sx={{ px: 3 }} onClick={toggleDrawer} >
              <ListItemIcon>
                <RotationArrow rotate={isRotated.toString()} ></RotationArrow>
              </ListItemIcon>
              <ListItemText />
            </ListItemButton>
            <Divider />
            <ListItem disablePadding sx={{ display: 'block' }}>
              <ListItemButton sx={dynamicStyles.iconButton} onClick={() => incrementBlock('title')} >
                <ListItemIcon sx={dynamicStyles.listItem} >
                  <TitleIcon />
                </ListItemIcon>
                <ListItemText primary='Título' sx={dynamicStyles.iconText} />
              </ListItemButton>
              <ListItemButton sx={dynamicStyles.iconButton} onClick={() => incrementBlock('coverImg')} >
                <ListItemIcon sx={dynamicStyles.listItem}>
                  <WallpaperIcon />
                </ListItemIcon>
                <ListItemText primary='Capa' sx={dynamicStyles.iconText} />
              </ListItemButton>
              <ListItemButton sx={dynamicStyles.iconButton} onClick={() => incrementBlock('text')}>
                <ListItemIcon sx={dynamicStyles.listItem}>
                  <ViewStreamIcon />
                </ListItemIcon>
                <ListItemText primary='Bloco de texto' sx={dynamicStyles.iconText} />
              </ListItemButton>
              <ListItemButton sx={dynamicStyles.iconButton} onClick={() => incrementBlock('image')} >
                <ListItemIcon sx={dynamicStyles.listItem}>
                  <ImageIcon />
                </ListItemIcon>
                <ListItemText primary='Imagem' sx={dynamicStyles.iconText} />
              </ListItemButton>
              <ListItemButton sx={dynamicStyles.iconButton} onClick={() => incrementBlock('video')} >
                <ListItemIcon sx={dynamicStyles.listItem}>
                  <LibraryAddIcon />
                </ListItemIcon>
                <ListItemText primary='Anexo com link' sx={dynamicStyles.iconText} />
              </ListItemButton>
              <ListItemButton sx={dynamicStyles.iconButton} onClick={() => incrementBlock('footerText')} >
                <ListItemIcon sx={dynamicStyles.listItem}>
                  <ShortTextIcon />
                </ListItemIcon>
                <ListItemText primary='Rodapé' sx={dynamicStyles.iconText} />
              </ListItemButton>
              <ListItemButton sx={dynamicStyles.iconButton} onClick={() => incrementBlock('footerImg')} >
                <ListItemIcon sx={dynamicStyles.listItem}>
                  <BurstModeIcon />
                </ListItemIcon>
                <ListItemText primary='Logo de Rodapé' sx={dynamicStyles.iconText} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>

      </WrapperBox>
      <StyledBox sx={{ flex: 1 }} open={open}>
        <DraggableArea 
          allBlocks={allBlocks} 
          updateAllBlocks={updateAllBlocks} 
          getOrder={getOrder} 
          getValue={getValue} 
          content={content} 
          dynamicContent={dynamicContent} 
          filesNames={filesNames}
        />

        <div ref={messagesEndRef} />
      </StyledBox>
    </Container>
  );
};

export default CustomTemplate;