import { useEffect, useState } from 'react';
import { Box, OutlinedInput, MenuItem, FormControl, Select } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SelectGroup = ({ name, getSelectedGroup, nameVariable, URL }) => {
  const [group, setGroup] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(group[0] || '');
  
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const hoverColor = theme.palette.primary.light;
  
  useEffect(() => {
    getSelectedGroup(selectedGroup);
  }, [getSelectedGroup, selectedGroup]);
  
  useEffect(() => {
    (async () => {
      const options = {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        },
        credentials: 'include',
      }
  
      const response = await (await fetch(URL, options)).json();
      
      const groups = response.groups;
  
      const newSet = new Set(group);
  
      groups.forEach(group => {

        if (!newSet.has(group[nameVariable])) {
          newSet.add(group[nameVariable]);
          setGroup([...newSet]);
        }
      });
  
    })();

    setSelectedGroup(group[0]);
    
  }, [group, nameVariable, URL]);

  return (
    <Box style={styles.container}>
      <FormControl style={styles.formControlBox}>
        <Select
          name={name}
          sx={{
            borderRadius: '10px',
            height: '40px',
            textAlign: 'center',
            color: primaryColor,
            backgroundColor: ' rgb(52, 114, 189, 0.1)',
            '&.MuiInputBase-formControl': {
              width: '100%'
            },
            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: primaryColor, 
                border: '2px solid rgb(52, 114, 189, 0.7)',
              },
              '&:hover fieldset': {
                borderColor: hoverColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: primaryColor,
              },

            },
            '.MuiSvgIcon-root ': {
              fill: '#3472BD !important',
            }
          }}
          inputProps={{
            MenuProps: {
              MenuListProps: {
                  sx: {
                    overflowY: 'auto',
                    maxHeight: '20em',
                    color: primaryColor,
                  }
                }
              }
            }}
          displayEmpty
          value={selectedGroup || ''}
          onChange={(e) => setSelectedGroup(e.target.value)}
          input={<OutlinedInput />}
        >
          {group.map((item) => (
            <MenuItem
              key={item}
              value={item}
            >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

const styles = {
  container: {
    width: '100%'
  },

  formControlBox: {
    width: '100%'
  }
}

export default SelectGroup;
