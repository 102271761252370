const MAILER_URL = process.env.REACT_APP_MAILER_URL;

const verifyAuth = async () => {

  let auth;
  
  const options = {

    method: 'POST',
    credentials: 'include'

  };

  await (async () => {

    try {
      const response = await fetch(`${MAILER_URL}/auth`, options);

      if (response.status !== 200) auth = false;
      else auth = true;  

    } catch (error) {
   
      auth = false;

    };

  })();

  return auth;

};

export default verifyAuth;