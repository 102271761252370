import { useState, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';

const CheckboxCode = ({ getSourceCodeValue }) => {
  const [sourceCode, setSouceCode] = useState(false);

  useEffect(() => {
    getSourceCodeValue(sourceCode);
  }, [getSourceCodeValue, sourceCode]);

  return (
    <Box>
      <FormControlLabel
        style={styles.formControl}
        onClick={() => setSouceCode(!sourceCode)}
        control={<Checkbox checked={sourceCode} />}
        label={<Typography variant='body3'
        style={styles.label}>Código Fonte</Typography>}
      />
    </Box>
  );
};

const styles = {

  formControl: {
    display: 'flex',
    alignItems: 'center',
  },

  label: {
    marginLeft: '5px'
  }

};

export default CheckboxCode;