import { useEffect, useState } from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import verifyAuth from './verifyAuth';

const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const location = useLocation();

  useEffect(() => {

    (async () => {
      const auth = await verifyAuth();

      setIsAuthenticated(auth);

    })();

  }, [location]);

  if (isAuthenticated === null) {

    return (
      <Box style={styles.container}>
        <CircularProgress style={styles.loading} />
      </Box>
    )
  };

  return isAuthenticated ? <Outlet /> : <Navigate to='/login' />;
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  },

  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 auto',
    width: '5%',
    height: '5%',
  }
};

export default PrivateRoute;
