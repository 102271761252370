const downloadBox1 = `
<td style="padding: 10px 40px 40px 40px;" class="esd-structure" align="left">
  <table cellpadding="0" cellspacing="0" width="100%">
    <td width="520" class="esd-container-frame" align="center" valign="top" style="max-width:520px">

    <!--[if !mso]><!-->
    <table cellpadding="0" cellspacing="0" width="80%" style="border-left: 1px solid #0b5394;border-right:1px solid #0b5394;border-top:1px solid #0b5394;border-bottom:1px solid #0b5394; border-radius: 15px; border-collapse: separate;">
        <td class="esd-block-text" align="left"
          style="border-radius: 15px; background-color: rgb(247,247,247); padding: 20px">
          <h5 style="color: #333333; font-size: 18px;">Download
          </h5>
        </td>
        <tr>
          <td align="center" class="esd-block-spacer" style="font-size:0" bgcolor="#0b5394">
            <table border="0" width="100%" height="100%" cellpadding="0" cellspacing="0">
              <td style="background: unset; height:0.5px; width:100%; margin:0px 0px 0px 0px;">
              </td>
            </table>
          </td>
        </tr>
        <tr>
          <td style="line-height: 100%; font-size: 14px; color: #333333; padding: 20px; text-decoration: none"
            align="left" class="esd-block-text">
            <p class="link-personalized">{links}</p>
          </td>
        </tr>
      </table>

    <!--<![endif]-->

    <!--[if mso]>
    <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" arcsize="5%" fillcolor="#edf2fb" stroke="f" style="height: auto; width: 80%;" 
        strokecolor="#0b5394" stroke-weight="1px" stroked="t" corner="15px" style="border-radius: 15px; width: 500px; height: 140px">
    <v:textbox style="mso-fit-shape-to-text: true;" inset="0,0,0,0">
    
    <table cellpadding="0" cellspacing="0" style="width: 100%">
        <tr>
            <td class="esd-block-text" align="left"
                style="border-radius: 15px; padding: 20px">
                <h5 style="color: #333333; font-size: 18px;">Download</h5>
            </td>
        </tr>
        
        <tr>
            <td style="line-height: 100%; font-size: 14px; color: #333333; padding: 20px 0px 0px 20px; text-decoration: none; border-top: 1px solid #0b5394;" align="left" class="esd-block-text">
                <font class="link-personalized" style="color: #333333 !important">{links}</font>
            </td>
        </tr>
    </table>
    
    </v:textbox>
</v:roundrect>
<![endif]-->


  </td>
</table>
</td>
`

export default downloadBox1;