import { useRef } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { Block, ItemDraggable, ContentBox } from './DraggableBlock.styles';

const itemTypes = {
  BOX: 'box'
};

const DraggableBlock = ({ id, content, handleDrop }) => {
  const boxRef = useRef(null);
  const dragRef = useRef(null);

  const [, dropRef] = useDrop({
    accept: itemTypes.BOX,
    drop: (item) => {
      handleDrop(item.id, id);
    },
    collect: monitor => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver()
    })
  });

  const [{ isDragging }, drag] = useDrag({
    type: itemTypes.BOX,
    item: { type: itemTypes.BOX, id },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    })
  });

  const handleContentBoxClick = (e) => {
    e.stopPropagation();
  };

  drag(dragRef);
  dropRef(boxRef);

  return (
    <Block className='box' ref={boxRef} isdragging={isDragging.toString()}>
      <ItemDraggable ref={dragRef}>
        <DragIndicatorIcon />
      </ItemDraggable>
      <ContentBox onClick={handleContentBoxClick}>
        {content}
      </ContentBox>
    </Block>
  );
};

export default DraggableBlock;