import { styled } from '@mui/system';
import { Box } from '@mui/material';

export const Container = styled(Box)(() => ({
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    borderRadius: '10px',
}));

export const BlankBox = styled(Box)(({ theme }) => ({
    width: '100%', 
    backgroundColor: 'lightgray', 
    cursor: 'pointer', 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center',
    color: theme.palette.primary.main,
    borderRadius: '10px',
    textAlign: 'center',
    fontSize: '14px',
    margin: '5px',
    padding: '1px',
}));

export const Image = styled('img')(() => ({
    cursor: 'pointer',
    width: '100%',
    borderRadius: '10px',  
}));