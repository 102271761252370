import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { BlankBox, Container, Image } from './ImageDrag.styles';

const ImageDrag = ({ getValue, name, savedValue }) => {
  const [src, setSrc] = useState(null);

  useEffect(() => {
    getValue({
      [name]: src
    })
  }, [getValue, src, name]);

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      if (file.size > 1000000) {
        alert('A imagem é muito grande! Tamanha máximo 1MB!')
        return;
      }

      reader.onload = () => {
        const base64String = reader.result;
        if (file.type === 'image/svg+xml') {
          alert('Formato xvg+xml não é permitido!')
          return;
        } else {
          setSrc(base64String);
        }
      };
  
      reader.readAsDataURL(file);
    }
  };
  
  useEffect(() => {
    if (savedValue) setSrc(savedValue);
  }, [savedValue])
  
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif'],
     },
  });

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />

      {src ? (
        <Image src={src} alt='Dropped' name={name}></Image>
      ) : (
        <BlankBox>
          Clique ou arraste aqui para subir uma imagem
        </BlankBox>
      )}

    </Container>
  );
};

export default ImageDrag;