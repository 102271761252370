import { Upload } from 'antd';
import { Box } from '@mui/material';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';

const { Dragger } = Upload;

const UploadContact = ({ getFile, disabled }) => {

  const handleUpload = ({ fileList }) => {

    if (fileList.length > 1) return;

    const formData = new FormData();

    fileList.forEach(file => {
      formData.append('file', file.originFileObj);
    });

    getFile(formData);

  }

  return (
    <Box style={styles.divDragger}>
      <Dragger
        style={styles.dragger}
        disabled={disabled}
        maxCount={1}
        name='file'
        beforeUpload={() => false}
        onChange={(e) => handleUpload(e)}
        accept='.xlsx'
      >
        <Box style={styles.draggerIcon}><UploadFileOutlinedIcon /></Box>
        <p style={styles.draggerLabel}>Clique ou arraste aqui para upload</p>
      </Dragger>
    </Box>
  )
};

const styles = {
  divDragger: {
    height: '7em',
  },

  dragger: {
    borderColor: '#008747',
    borderRadius: '15px',
    padding: '0 2px',
    flexDirection: 'row',
  },

  draggerIcon: {
    color: '#008747',
    fontWeight: '500',
  },

  draggerLabel: {
    fontWeight: '500',
    margin: 0,
    padding: 0,
  },
}

export default UploadContact;