import { Button, Result } from 'antd';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {
  const navigate = useNavigate()

  return (
    <Box style={styles.container}>
      <Result
        status='404'
        title='404'
        subTitle='Desculpe, a página que você visitou não existe.'
        extra={<Button onClick={() => navigate('/mail')} type='primary'>Ir para Home</Button>}
      />
    </Box>
  )
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  },
}
export default Error404;