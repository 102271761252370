import { useState } from 'react';
import { Box } from '@mui/material';
import { Player} from '@lottiefiles/react-lottie-player';
import TextEditor from './TextEditor';
import ButtonSubmit from './ButtonSubmit'

import { Menu, Select } from '../../components';

const WHATSAPP_URL = process.env.REACT_APP_WHATSAPP_URL;

const WhatsappMessage = () => {

  const [editorValueNoTags, setEditorValueNoTags] = useState('');
  const [call, setCall] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState('');

  const getEditorData = async (_, valueEditorNoTags) => setEditorValueNoTags(valueEditorNoTags);
  const getSelectedGroup = (data) => setSelectedGroup(data);

  const verifyFields = (form) => {
    const formData = new FormData(form);
    setCall(!call);

    formData.append('group', selectedGroup);
    formData.append('content', editorValueNoTags);

    if (!editorValueNoTags.trim()) return;

    return true;

  }

  const handleSubmit = async () => {

    try {
      const options = {

        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify({ content: editorValueNoTags, selectedGroup: selectedGroup }),
        credentials: 'include'
      };

      await fetch(`${ WHATSAPP_URL }`, options);

    } catch (error) {

      return 'Error'
    }

  }

  return (
    <Box style={styles.container}>

      <Menu tabState='whatsapp' indexSelected='' />

      <Box style={styles.section}>

        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit}
          style={styles.form}
        >

          <Select 
            getSelectedGroup={getSelectedGroup} 
            name='Grupos' 
            nameVariable='nm_send_group_whatsapp' 
            URL={`${ WHATSAPP_URL }/sendGroup/getAll`} 
          />

          <Box style={styles.textEditorContainer}>
            <TextEditor call={call} getEditorData={getEditorData}  />
          </Box>

          <Box style={styles.buttonContainer}>
            <ButtonSubmit
              verifyFields={verifyFields}
              handleSubmit={handleSubmit}
              text='Enviar Mensagens'
              description='Deseja mesmo enviar as mensagens?'
              descriptionSuccess='Mensagens enviadas com sucesso!'
            />
          </Box>

        </Box>

        <Box style={styles.imageContainer}>
        <Player
          hover
          src="https://assets3.lottiefiles.com/packages/lf20_5rImXbDsO1.json"
          style={styles.image}
          >
        </Player>
        </Box>
        
      </Box>

    </Box>
  );
}

const styles = {
  container: {
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  
  section: {
    padding: '30px',
    display: 'flex',
    width: '100%',
    justifycontainer: 'space-around',
    alignItems: 'center',
    height: 'calc(100vh - 7em)' // 7em is the size of the menu
  },

  form:{
    width: '50%',
    height: '70%',
    paddingLeft: '50px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',  
  },

  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',  
  },

  imageContainer: {
    width: '50%',
    display: 'flex'
  },

  image: {
    width: '90%', 
    height: '100%', 
  },

  textEditorContainer: {
    height: '100%', 
    minwidth: '700px',
    width: '100%',
    margin: '40px 0 80px',
  }
}

export default WhatsappMessage;