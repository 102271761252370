import { useState } from 'react';
import { Box } from '@mui/material';
import { Upload, Modal } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const UploadFile = ({ getFiles }) => {

  const [buffer, setBuffer] = useState();
  const [previewOpen, setPreviewOpen] = useState(false);

  const handlePreview = async (file) => {

    const files = await file.originFileObj;

    const reader = new FileReader();
    reader.readAsArrayBuffer(files);

    reader.onload = () => setBuffer(reader.result);

    setPreviewOpen(true);

  };

  const handleUpload = ({ fileList }) => {

    const formData = new FormData();

    fileList.forEach(file => {
      file.url = window.location.href;
      formData.append('files', file.originFileObj);
    });

    getFiles(formData);

  }

  const handleCancel = () => setPreviewOpen(false);

  return (
    <Box style={styles.container}>
      <Dragger
        style={styles.dragger}
        onPreview={handlePreview}
        name='file'
        multiple={true}
        beforeUpload={() => false}
        onChange={(e) => handleUpload(e)}
        accept='.pdf,.ics,.xlsx'
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined style={styles.icon} />
        </p>
        <p className='ant-upload-text'> Clique ou arraste aqui para upload </p>
        <p className='ant-upload-hint'> Suporta um ou mais arquivos de PDF. Estritamente proibido o upload de dados não autorizados. </p>
      </Dragger>
      <Modal width={1000} open={previewOpen} title='Pré-visualização' footer={null} onCancel={handleCancel}>
        {buffer && (
          <embed style={styles.embed} src={URL.createObjectURL(new Blob([buffer], { type: 'application/pdf' }))} type='application/pdf' />
        )}
      </Modal>
    </Box>
  )
}

const styles = {
  container: {
    height: '50vh',
  },

  dragger: {
    backgroundColor: 'rgb(195, 216, 233, 0.2)',
    borderRadius: '35px',
    padding: '0 2px'
  },

  icon: {
    color: '#3472BD'
  },

  embed:{
    width: '100%',
    minHeight: '60vh'
  },

  errorText: {
    color: '#D44E5A',
    textAlign: 'center',
    marginTop: '10px'
  }
}

export default UploadFile;