import { useEffect, useState } from 'react';
import { Box, OutlinedInput, MenuItem, FormControl, Select, InputLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const SelectField = ({ name, getSelectedValue, nameVariable, URL, isEmpty, label }) => {
  const [value, setValue] = useState([]);
  const [selectedValue, setSelectedValue] = useState();

  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const hoverColor = theme.palette.primary.light;

  useEffect(() => {
    getSelectedValue(selectedValue);
  }, [getSelectedValue, selectedValue]);

  const idItem = `id_${nameVariable}`;
  const nameItem = `nm_${nameVariable}`;

  useEffect(() => {
    (async () => {
      const options = {
        method: 'GET',
        credentials: 'include',
      }

      const response = await (await fetch(URL, options)).json();
      const items = response.message;

      setValue(items);

      if (isEmpty) return;

      setSelectedValue({ id: items[0]?.[idItem], name: items[0]?.[nameItem] });
    })();

  }, [nameVariable, URL, idItem, nameItem, isEmpty]);

  const handleChange = (e) => {
    const id = e.target.value;
    const name = value.find(item => item[idItem] === id)[nameItem];
    setSelectedValue({ id, name });
  };

  return (
    <Box style={styles.container}>
      <FormControl style={styles.formControlBox}>
      <InputLabel 
          size='small' 
          id='demo-simple-select-helper-label'
          sx={{
            color: primaryColor,
            '&.Mui-focused': {
              color: primaryColor,
            },
          }}
        > 
          {label} 
        </InputLabel>
        <Select
          name={name}
          sx={{
            borderRadius: '10px',
            height: '40px',
            textAlign: 'center',
            color: primaryColor,
            backgroundColor: ' rgb(52, 114, 189, 0.1)',
            '&.MuiInputBase-formControl': {
              width: '100%'
            },
            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: primaryColor,
                border: '2px solid rgb(52, 114, 189, 0.7)',
              },
              '&:hover fieldset': {
                borderColor: hoverColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: primaryColor,
              },

            },
            '.MuiSvgIcon-root ': {
              fill: '#3472BD !important',
            }
          }}
          inputProps={{
            MenuProps: {
              MenuListProps: {
                sx: {
                  overflowY: 'auto',
                  maxHeight: '20em',
                  color: primaryColor,
                }
              }
            }
          }}
          displayEmpty
          value={selectedValue?.id || ''}
          onChange={handleChange}
          input={<OutlinedInput label={label} />}
        >
          {value?.map((item, index) => (
            <MenuItem
              key={index}
              value={item[idItem]}
            >
              {item[nameItem]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

const styles = {
  container: {
    width: '100%'
  },

  formControlBox: {
    width: '100%'
  }
}

export default SelectField;
