import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';

const InputField = ({ label, width, name, call, defaultValue, type, disabled, optional }) => {
  const [value, setValue] = useState(defaultValue || '');
  const [error, setError] = useState(false);
  const patterns = {
    name: /[^\p{L}\d\s]+/gu,
    email: /[^a-zA-Z0-9@._-]+/g,
    group: /[^\p{L}\d\s+()\-_]+/gu,

    default: null
  }

  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const hoverColor = theme.palette.primary.light;
  const errorColor = theme.palette.error.main;

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    if (call && (!value)) setError(true);
  }, [call, value]);

  const handleInputChange = (event) => {
    const input = event.target.value.replace(patterns[type], '');

    setValue(input);
    setError(!input);
  };

  return (
    <TextField
      id='outlined-basic'
      inputProps={{ maxLength: 250 }}
      error={!optional && error}
      autoComplete='off'
      fullWidth
      label={label}
      required={!optional}
      variant='outlined'
      size='small'
      type='assunto'
      value={value}
      onChange={handleInputChange}
      disabled={disabled}
      name={name}
      sx={{
        width: width,
        '& fieldset': { borderColor: primaryColor, borderRadius: '10px', },
        '& .MuiOutlinedInput-root': {
          '&:hover:not(.Mui-disabled) fieldset': {
            borderColor: (!optional && error) ? errorColor : hoverColor,
          },
          '&.Mui-focused fieldset': {
            borderColor: (!optional && error) ? errorColor : primaryColor
          }
        },
        '& .MuiInputLabel-shrink': {
          color: (!optional && error) ? errorColor : primaryColor,
        },
      }}
    />
  );
};

export default InputField;