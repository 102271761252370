import { useState, useEffect } from 'react';
import { Space, Switch } from 'antd';

const SwitchBox = ({ handleAction, options, changeColor, width, checked, ...props }) => {
  const [backgroundColor, setBackgroundColor] = useState('#3472BD');

  useEffect(() => {
    
    if (!checked) setBackgroundColor('#af1816')
    else setBackgroundColor('#3472BD')

  }, [checked]);

  const styles = {
    switch: {
      backgroundColor: !changeColor ? '#3472BD' : backgroundColor,
      marginLeft: '30px',
      width: width || '6em',
    },
  };

  return (
    <Space>
      <Switch
        {...props}
        style={styles.switch}
        onChange={(checked) => handleAction(checked)}
        checkedChildren={options[0]}
        unCheckedChildren={options[1]}
        checked={checked}
      />
    </Space>
  );
  
};

export default SwitchBox;
