import { useState, useEffect } from 'react';
import { Box, Button, Modal, CircularProgress } from '@mui/material';
import QRCode from 'qrcode.react';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

const WHATSAPP_URL = process.env.REACT_APP_WHATSAPP_URL.split('//')[1];

const ButtonSend = ({ verifyFields, handleSubmit, text, disabled }) => {
  const [qrCode, setQrCode] = useState('');
  const [sessionWhatsapp, setSessionWhatsapp] = useState(false);
  const [wsConnection, setWsConnection] = useState();

  const [openModalQrCode, setOpenModalQrCode] = useState(false);

  const handleCancelModalQrCode = () => {
    wsConnection.close();
    setOpenModalQrCode(false);
  }

  useEffect(() => {
    if (wsConnection) return () => wsConnection.close();
  }, [wsConnection]);

  const showModal = async (e) => {
    const valid = await verifyFields(e.currentTarget.form);

    if (!valid) return;
    initWsConn();
    setSessionWhatsapp(false);
    setOpenModalQrCode(true);

    await handleSubmit();

  };

  const initWsConn = () => {
    let protocol = 'wss';

    if (process.env.NODE_ENV === 'development') protocol = 'ws';
    
    const ws = new WebSocket(`${protocol}://${ WHATSAPP_URL }`);

    ws.onmessage = (event) => {

      const data = JSON.parse(event.data);

      if (data.status === 'ready') {
        setSessionWhatsapp(true);

        return;
      }

      setQrCode(data.qrCode);

    };

    setWsConnection(ws);
  }

  return (
    <>

      <Button style={styles.button} color='secondary' variant='contained' onClick={showModal} disabled={disabled}>{text}</Button>

      <Modal
        open={openModalQrCode}
        onClose={handleCancelModalQrCode}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        style={styles.modal}
      >
        <Box style={styles.modalQrCodeContainer}>
          {(qrCode && !sessionWhatsapp) && <QRCode fgColor='green' value={qrCode} size={200} />}
          {!qrCode && <CircularProgress />}

          {sessionWhatsapp && (
            <Box style={styles.modalContent}>

              <MarkEmailReadIcon style={styles.iconModalSuccess} />
              <Box>Mensagens Enviadas com sucesso!</Box>
            </Box>
          )}

          <Box style={styles.buttonModalQrCodeContainer}>
            {!sessionWhatsapp ? (<Button style={styles.buttonModal} variant='contained' onClick={handleCancelModalQrCode} disabled={disabled}>Cancelar</Button>)
              : (<Button style={styles.buttonModalOk} color='secondary' variant='contained' onClick={handleCancelModalQrCode} disabled={disabled}>Ok</Button>)
            }

          </Box>
        </Box>
      </Modal>
    </>
  )
};

const styles = {
  button: {
    width: 'fit-content',
    height: '35px',
    borderRadius: '9px',
    textTransform: 'none',
  },

  modal: {
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

  },

  modalContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },

  iconModalSuccess: {
    fontSize: '150px',
    color: '#008747'
  },

  buttonModal: {
    width: 'fit-content',
    height: '35px',
    borderRadius: '9px',
    textTransform: 'none',
    backgroundColor: 'gray'
  },

  buttonModalOk: {
    width: 'fit-content',
    height: '35px',
    borderRadius: '9px',
    textTransform: 'none',
  },

  modalQrCodeContainer: {
    backgroundColor: 'white',
    width: '35%',
    height: '35%',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  buttonModalQrCodeContainer: {
    width: '100%',
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'bottom',
  }

};

export default ButtonSend;