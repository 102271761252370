import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import TableCompanies from './TableCompanies';
import { SelectField, InputField, ButtonDelete, ButtonSend } from '../../../../../components';

const MAILER_URL = process.env.REACT_APP_MAILER_URL;

const EditGroup = () => {
  const [selectedGroup, setSelectedGroup] = useState();
  const [groupEdited, setGroupEdited] = useState(selectedGroup);
  const [call, setCall] = useState(false);

  const getSelectedGroup = async (data) => setSelectedGroup(data);

  useEffect(() => {
    if (!selectedGroup) return;
    setGroupEdited(selectedGroup);

  }, [selectedGroup]);

  const verifyFields = (form) => {
    const data = new FormData(form);

    setCall(!call);

    setGroupEdited(data.get('group'));

    for (const [, value] of data.entries()) if (value === '') return;

    return true;

  }

  const handleSubmit = async () => {

    const groupData = {
      group: groupEdited.trim(),
      selectedGroup: selectedGroup?.id
    };

    for (let key in groupData) if (!groupData[key]) return false;

    const options = {
      method: 'PATCH',
      body: JSON.stringify(groupData),
      headers: {
        'Content-type': 'application/json'
      },
      credentials: 'include'
    }

    const response = await fetch(`${MAILER_URL}/group/update`, options);

    const reponseBody = await response.json();
    const message = reponseBody.message;

    if (response.status === 200) return;
    else return message;

  };

  const handleDelete = async () => {

    const options = {
      method: 'DELETE',
      credentials: 'include',
    }

    const response = await fetch(`${MAILER_URL}/group/delete/${selectedGroup.id}`, options);

    if (response.status === 204) return;
    else return 'Falha ao deletar o grupo';

  };

  return (
    <Box component='main' style={styles.container}>

      <fieldset style={styles.fieldset}>
        <legend style={styles.title}>Editar Grupo</legend>

        <Box component='form' style={styles.contentBox} >

          <Box style={styles.divisor}>

            <InputField
              label='Nome do Grupo'
              name='group'
              type='group'
              defaultValue={groupEdited?.name}
            />

          </Box>

          <SelectField
            getSelectedValue={getSelectedGroup}
            nameVariable='send_group'
            URL={`${MAILER_URL}/group/getAll`}
            isEmpty={false}
            label='Grupo'
          />

          <Box style={styles.buttonBox}>

            <ButtonDelete
              text='Deletar Grupo'
              handleDelete={handleDelete}
              description='Tem certeza de que deseja deletar este grupo?'
              descriptionSuccess='Grupo deletado com sucesso!'
            />

            <ButtonSend
              verifyFields={verifyFields}
              value={groupEdited}
              handleSubmit={handleSubmit}
              text='Editar Grupo'
              description={`Tem certeza de que deseja editar o grupo ${selectedGroup?.name}?`}
              descriptionSuccess={`O grupo ${selectedGroup?.name} foi atualizado com sucesso para ${groupEdited}!`}
            />

          </Box>
        </Box>
      </fieldset>

      <Box style={{ margin: 10}}>
        <TableCompanies 
          selectedGroup={selectedGroup} 
          pathname='/group/getOne' 
          columns={['Nome da Empresa', 'Português', 'Inglês', 'Ativa']}
          items={['nm_company', 'is_portuguese', 'is_english', 'is_active']}
        />
      </Box>

    </Box>
  );
};

const styles = {
  container: {
    width: '50%',
    marginTop: '2em'
  },

  fieldset: {
    borderRadius: '15px',
    border: 'solid',
    borderWidth: '3px',
    borderColor: '#202956',
    height: 'fit-content',
  },

  title: {
    color: '#202956',
    fontSize: '30px',
    margin: '0 auto',
    fontWeight: '500',
    padding: '0 10px'
  },

  contentBox: {
    padding: '6% 4% 4% 4%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
  },

  divisor: {
    marginBottom: '15px',
  },

  buttonBox: {
    padding: '4% 4% 0 4%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    margin: '0 auto',
  },

}

export default EditGroup;