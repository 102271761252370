const downloadBox2 = `
    <table class="container container-pdfs" role="presentation" cellspacing="0" cellpadding="0" border="0"
    align="center" width="600">
        <tr>
          <td class="dynamic-content">
              <h4>Download</h4>
               {links}
         </td>
      </tr>
    </table>
`;

export default downloadBox2;