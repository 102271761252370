import { useState, useContext } from 'react';
import { Button, TextField, Box, Typography, CircularProgress } from '@mui/material';
import { AuthContext } from '../../context/auth';
import Alert from '@mui/material/Alert';
import AgroconsultLogo from '../../assets/agroconsultPositive.svg';

const Login = () => {

  const [userInputError, setUserInputError] = useState(false);
  const [passwordInputError, setPasswordInputError] = useState(false);

  const { login, loading, error } = useContext(AuthContext);

  const handleSubmit = (e) => {

    e.preventDefault();

    const data = new FormData(e.currentTarget);

    const user = data.get('user').trim();
    const password = data.get('password');

    if (user === '') setUserInputError(true);
    if (password === '') setPasswordInputError(true);

    for (const [, value] of data.entries()) if (value === '') return;

    login(user, password);

  };

  return (
    <Box component='main' style={styles.container}>

      <fieldset style={styles.fieldset}>

        <Box style={styles.subtitleBox}>

          <img src={AgroconsultLogo} alt='' style={styles.agroconsultLogo} />

          <Typography component='h1' variant='h4' color={'white'} style={styles.subtitle}>
            Sua plataforma Agro de comunicação
          </Typography>
        </Box>

        <Box component='form' autoComplete='off' onSubmit={handleSubmit} noValidate style={styles.form}>

          <Typography component='h1' variant='h4' color={'white'} style={styles.subtitle}>
              Entre com suas credenciais
          </Typography>

            <TextField
              sx={{
                '& > :not(style)': { color: 'white', borderColor: 'white', borderRadius: '10px' },
                '& fieldset': { borderColor: 'white' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}

              helperText={userInputError && 'Campo obrigatório!'}
              color='custom'
              margin='normal'
              required
              fullWidth
              label='Usuário'
              name='user'
              autoFocus
              onChange={(e) => e.target.value ? setUserInputError(false) : setUserInputError(true)}
            />
              <Box style={styles.divisor}></Box>
            <TextField
              sx={{
                '& > :not(style)': { color: 'white', borderColor: 'white', borderRadius: '10px' },
                '& fieldset': { borderColor: 'white' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'white',
                  },
                  '&:hover fieldset': {
                    borderColor: 'white',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'white',
                  },
                },
              }}
              helperText={passwordInputError && 'Campo obrigatório!'}
              color='custom'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type='password'
              onChange={(e) => e.target.value ? setPasswordInputError(false) : setPasswordInputError(true)}
            />

          {error && <Alert variant='' severity='error' style={styles.errorAlert}> Usuário e/ou senha incorreto!</Alert>}

          <Box style={styles.buttonBox}>
            <Button
              color='secondary'
              type='submit'
              fullWidth
              variant='contained'
              style={styles.button}

            >
              {loading ? <CircularProgress style={styles.progress} /> : 'Entrar'}

            </Button>
          </Box>

        </Box>
      </fieldset>
    </Box>

  );
}

const styles = {

  container: {
    backgroundColor: '#202956',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
  },

  fieldset: {
    borderRadius: '15px',
    border: '0',
    backgroundColor: 'rgb(257, 257, 257, 0.25)',
    minWidth: '600px',
    width: 'fit-content',
    minHeight: '480px',
    height: 'fit-content',
    padding: '3em 2em',
  },

  title: {
    color: 'white',
    fontSize: '75px',
    margin: '0 auto',
    fontWeight: '500'
  },

  subtitleBox: {
    marginBottom: '4em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  agroconsultLogo: {
    height: '12em'
  },

  subtitle: {
    marginLeft: '0.25em',
    fontSize: '16px',
  },

  form: {
    padding: '0 1.5em',
    height: '100%',
    margin: '0 auto',
    marginTop: '6em'
  },

  divisor: {
    backgroundColor: 'pink',
    margin: '2% 0'
  },

  buttonBox: {
    display: 'flex',
    margin: '0 auto',
    width: '100%'
  },

  button: {
    margin: '2em 0',
    borderRadius: '10px',
    height: '35px',
    textTransform: 'none'
  },

  errorAlert: {
    color: '#D44E5A',
  },

  progress: {
    color: 'white',
    width: '1.5em',
    height: '1.5em',

  }

}

export default Login;