import { Box, TextField } from '@mui/material';
import { styled } from '@mui/system';

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  background-color: white;
`;

export const TitleTextField = styled(TextField)(({ theme }) => ({
  width: '95%',

  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    fontSize: '20px',

    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.light,
    },
  },
}));