import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, OutlinedInput, InputLabel, MenuItem, FormControl, Select, Chip } from '@mui/material';

const MultipleGroupSelect = ({ getSelectValue, call, URL, nameProps, value }) => {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  const errorColor = theme.palette.error.main;
  const [groups, setGroups] = useState([]);
  const [error, setError] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);

  useEffect(() => {

    if (call && selectedGroups.length === 0) setError(true);
    
  }, [call, selectedGroups]);

  useEffect(() => {
    if (typeof value === 'object') setSelectedGroups(value);
  }, [value]);

  useEffect(() => {
    (async () => {
      try {
        const options = {
          method: 'GET',
          credentials: 'include',
        };
        
        const response = await fetch(URL, options);
        const json = await response.json();
        const groups = json.groups;

        const newSet = new Set(groups);
        const newGroups = [];

        groups.forEach((group) => {
          if (!newSet.has(group[nameProps])) {
            newSet.add(group[nameProps]);
            newGroups.push(group[nameProps]);
          }
        });

        setGroups(newGroups);
      } catch (error) {
        // no handle error
      }
    })();

  }, [URL, nameProps]);

  const handleChange = (event) => {
    setSelectedGroups(event.target.value);

    if (event.target.value.length === 0) setError(true);
    else setError(false);
    
  };

  useEffect(() => {
    getSelectValue(selectedGroups);
  }, [getSelectValue, selectedGroups]);

  return (
    <Box>
      <FormControl fullWidth >
        <InputLabel
          size='small'
          id='demo-multiple-chip-label'
          sx={{
            color: error ? errorColor : primaryColor,
            '&.Mui-focused': {
              color: primaryColor,
            },
          }}
        >
          Selecione o(s) Grupo(s) de Envio
        </InputLabel>
        <Select
          sx={{
            borderRadius: '10px',
            color: primaryColor,
            backgroundColor: ' rgb(52, 114, 189, 0.1)',
            '&.MuiInputBase-formControl': {
              width: '100%',
            },
            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: primaryColor,
                border: '2px solid rgb(52, 114, 189, 0.7)',
              },
              '&:hover fieldset': {
                borderColor: error ? errorColor : primaryColor,
              },
              '&.Mui-focused fieldset': {
                borderColor: primaryColor,
              },
            },
            '.MuiSvgIcon-root ': {
              fill: '#3472BD !important',
            }
          }}
          inputProps={{
            MenuProps: {
              MenuListProps: {
                sx: {
                  overflowY: 'auto',
                  maxHeight: '20em',
                  color: primaryColor,
                }
              }
            }
          }}
          size='small'
          multiple
          error={error}
          color='primary'
          value={selectedGroups}
          onChange={handleChange}
          input={<OutlinedInput id='select-multiple-chip' label='Selecione o(s) Grupo(s) de Envio' />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {groups.map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default MultipleGroupSelect;