import { useEffect, useState } from 'react';

import { Container, TitleTextField } from './TitleDrag.styles';

const TitleDrag = ({ getValue, name, savedValue }) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    getValue({
      [name]: value
    });
  }, [getValue, value, name]);

  useEffect(() => {
    if (savedValue) setValue(savedValue);
  }, [savedValue])

  return (
    <Container>
      <TitleTextField
        id='outlined-basic'
        variant='outlined'
        value={value}
        name={name}
        onChange={(e) => setValue(e.target.value)}
      />
    </Container>
  );
}

export default TitleDrag;