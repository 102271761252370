import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import { Box } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export const WrapperBox = styled(Box)(() => ({
  backgroundColor: 'white', 
  borderRadius: '10px',
}));

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    zIndex: '0',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    height: 'fit-content',
    borderRadius: '10px',
    position: 'sticky',
    top: '0px',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme),
      },
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
      },
    }),
    '& .MuiDrawer-paper': {
      overflow: 'hidden',
      position: 'relative',
      boxShadow: 'none',
      border: 0,
    },
  }),
);

export const StyledBox = styled(Box)(({ theme, open }) => ({
  width: open ? drawerWidth : theme.spacing(7),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

export const Container = styled(Box)(() => ({
  width: '100%',
  backgroundColor: '#EDF1F5',
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  gap: 1,
  position: 'relative',
  height: '100%',
}));

export const RotationArrow = styled(ArrowForwardIosIcon)(({ rotate }) => ({
  fontSize: '18px',
  transform: rotate === 'true' ? 'rotate(180deg)' : 'rotate(360deg)',
  transition: 'transform 0.3s ease-in-out',
}));

