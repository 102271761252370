import { memo, useEffect, useState } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';

const MAILER_URL = process.env.REACT_APP_MAILER_URL;

const TableCompanies = memo(({ selectedGroup, pathname, items, columns }) => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!selectedGroup) return;

    setLoading(true);

    (async () => {

      const options = {
        method: 'POST',
        body: JSON.stringify({ idSendGroup: selectedGroup.id }),
        headers: {
          'Content-type': 'application/json'
        },
        credentials: 'include'
      };

      const response = await fetch(`${MAILER_URL}${pathname}`, options);

      const message = await response.json();

      if (!message[0]) setData(null);
      else setData(message);

      setLoading(false);

    })();

  }, [selectedGroup, pathname]);

  return (
    <Box component='main' style={styles.container}>

      <fieldset style={styles.fieldset}>
        <legend style={styles.title}> {selectedGroup?.name} </legend>
        {loading && (
          <Box style={styles.loadingBox}>
            <CircularProgress style={{ color: 'green' }} />
          </Box>
        )}
        {!loading && (
          <TableContainer>
            <Table aria-label='simple table'>
              <TableHead>
                {data && (
                  <TableRow style={{ backgroundColor: 'rgb(0, 135, 71, 0.9)' }}>
                    {columns.map((column, index) => (
                      <TableCell
                        style={{
                          ...styles.tableCellHead,
                          borderTopLeftRadius: index === 0 ? '10px' : undefined,
                          borderBottomLeftRadius: index === 0 ? '10px' : undefined,
                          borderTopRightRadius: (index + 1 === columns.length) ? '10px' : undefined,
                          borderBottomRightRadius: (index + 1 === columns.length) ? '10px' : undefined
                        }}
                        align={columns.length === (index + 1) ? 'right' : undefined}
                        key={column}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                )}

              </TableHead>
              <TableBody>
                {data?.map((row) => (
                  <TableRow
                    key={row.id_company}
                  >
                    {items.map((item, index) => (
                      <TableCell
                        align={items.length === (index + 1) ? 'right' : undefined}
                        key={item}
                      >
                        {typeof row[item] === 'boolean' ? (row[item] ? 'Sim' : 'Não') : row[item]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {!data && (
              <div style={styles.noCompanyFoundBox}>
                <span>Sem empresas no grupo selecionado</span>
              </div>
            )}
          </TableContainer>
        )}
      </fieldset>
    </Box>
  );
});

const styles = {
  container: {
    width: '100%',
    margin: '4% 0',
  },

  fieldset: {
    borderRadius: '15px',
    border: 'solid',
    borderWidth: '3px',
    borderColor: '#008747',
    height: 'fit-content',
    padding: '3%',
  },

  title: {
    color: '#008747',
    fontSize: '30px',
    margin: '0 auto',
    fontWeight: '500',
    padding: '0 10px'
  },

  tableCellHead: {
    color: 'white',
  },

  loadingBox: {
    display: 'flex',
    justifyContent: 'center'
  },

  noCompanyFoundBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  }
};

export default TableCompanies;