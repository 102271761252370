import { useState, useEffect } from 'react';
import { CustomTextarea } from '../../../../../styles/globals.styles';

const FooterDrag = ({ getValue, name, savedValue}) => {
  const [value, setValue] = useState('');

  useEffect(() => {
    getValue({
      [name]: value
    })
  }, [getValue, value, name]);

  useEffect(() => {
    if (savedValue) setValue(savedValue);
  }, [savedValue])

  return (
    <>
      <CustomTextarea value={value} onChange={(e) => setValue(e.target.value)} />
    </>
  );
};

export default FooterDrag;