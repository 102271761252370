import { useState, useEffect } from 'react';
import { Box, ListItem, ListItemButton, ListItemText, Checkbox, Pagination, Stack, InputBase } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import ButtonEditModal from './ButtonEditModal';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  margin: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const ContactList = ({ companyContacts, selectedCompany }) => {
  const [contactsChecked, setChecked] = useState([]);
  const [contactInformations, setContactInformations] = useState(companyContacts || []);
  const [selectedPage, setSelectedPage] = useState(1);
  const [searchedText, setSearchedText] = useState();

  useEffect(() => {
    setChecked([]);
    setSelectedPage(1);
  }, [selectedCompany]);

  useEffect(() => {
    setContactInformations(companyContacts || []);
  }, [companyContacts, contactsChecked]);

  useEffect(() => {
    setSelectedPage(1);
  }, [searchedText]);

  const handleToggle = (value) => () => {

    const currentIndex = contactsChecked.indexOf(value);
    const newChecked = [...contactsChecked];

    if (currentIndex === -1) newChecked.push(value);
    else newChecked.splice(currentIndex, 1);

    setChecked(newChecked);
  };

  let pagination = 0;
  let limitDataPerPage = 10;

  const showContacts = () => {
    let filteredData = [...contactInformations];

    filteredData.sort((item, item2) => {
        if (item.nm_contact < item2.nm_contact) return -1;
        
        if (item.nm_contact > item2.nm_contact) return 1;
        
        return 0;
      });

    if (searchedText) {
      const searchTerm = searchedText.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
      filteredData = filteredData.filter(item =>
        item.nm_contact.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm) ||
        item.de_contact.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchTerm)
      );
    }

    pagination = Math.ceil(filteredData.length / limitDataPerPage);

    const rows = filteredData.slice((selectedPage - 1) * limitDataPerPage, selectedPage * limitDataPerPage).map((value) => {
      return (
        <ListItem key={value.id_contact} disablePadding
          sx={{
            margin: 0,
            '&:nth-of-type(even)': {
              backgroundColor: '#f2f2f2',
            },
            '&:nth-of-type(odd)': {
              backgroundColor: '',
            },
          }}>
          <ListItemButton role={undefined} onClick={handleToggle(value)} dense style={styles.listItemButton}>
            <Checkbox
              edge='start'
              color='secondary'
              checked={contactsChecked.indexOf(value) !== -1}
              disableRipple
              sx={{ margin: 0, padding: 0, }}
            />
            <Box style={styles.contact}>
              <ListItemText style={styles.listItemText} primary={`${value.nm_contact}`} />
              <ListItemText style={styles.listItemText} primary={`${value.de_contact}`} />
            </Box>
          </ListItemButton>
        </ListItem>
      );
    });

    if (selectedPage > pagination && rows.length !== 0) setSelectedPage(1);

    return rows;
  };

  return (
    <Box component='main' style={styles.container}>

      <fieldset style={styles.fieldset}>
        <legend style={styles.title}> {selectedCompany.name} </legend>

        <Box style={styles.divisor}>

          <ButtonEditModal 
            contactsChecked={contactsChecked} 
            selectedCompany={selectedCompany} 
          />

          <Box style={styles.searchBox}>
            <Search style={styles.search}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                onChange={(e) => setSearchedText(e.target.value)}
                placeholder='Procurar…'
              />
            </Search>
          </Box>
        </Box>

        <Box component='form' style={styles.contactList}>

          {showContacts()}

        </Box>

        <Box style={styles.paginationBox}>
          <Stack spacing={2}>
            <Pagination
              hidePrevButton
              hideNextButton
              count={pagination}
              color='secondary'
              page={parseInt(selectedPage)}
              onClick={(e) => {
                const page = e.target.textContent;
                if (!isNaN(page)) setSelectedPage(page);
              }}
            />
          </Stack>
        </Box>
      </fieldset>
    </Box>
  );
}

const styles = {
  container: {
    width: '100%',
    margin: '4% 0'
  },

  fieldset: {
    borderRadius: '15px',
    border: 'solid',
    borderWidth: '3px',
    borderColor: '#008747',
    paddingBottom: '3%',
    height: 'fit-content',
  },

  title: {
    color: '#008747',
    fontSize: '30px',
    margin: '0 auto',
    fontWeight: '500',
    padding: '0 10px'
  },

  divisor: {
    display: 'flex',
    backgroundColor: 'rgb(0, 135, 71, 0.9)',
    borderRadius: '10px',
    justifyContent: 'space-between',
    margin: '3% 3% 1em 3% ',
    padding: '0.5em 0.5em',
    color: '#f7f7f7',
  },

  selectAllLabel: {
    margin: 0,
  },

  searchBox: {
    width: 'fit-content',
    alignItems: 'center',
    textAlign: 'center',
  },

  contactList: {
    margin: 0,
    padding: '0 4%',
  },

  contact: {
    display: 'flex',
    width: '100%',
  },

  paginationBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2.5em'
  },

  listItemButton: {
    margin: 0, 
    padding: '1em 0.5em', 
  },

  listItemText: {
    justifyContent: 'center', 
    display: 'flex', 
    width: '50%'
  }
}

export default ContactList;