import { useRef, useEffect, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableBlock from './DraggableBlock/DraggableBlock.jsx';
import { Typography } from '@mui/material';
import EmailTemplate from '../../../../../helpers/emailsTemplates/box2/emailTemplateBox2.js';
import RemoveIcon from '@mui/icons-material/Remove';
import ImageDrag from '../ImageDrag/index.jsx';
import TextareaDrag from '../TextareaDrag/index.jsx';
import TitleDrag from '../TitleDrag/index.jsx';
import VideoLinkDrag from '../VideoLinkDrag/index.jsx';
import FooterDrag from '../FooterDrag/index.jsx';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import AutorenewIcon from '@mui/icons-material/Autorenew';

import { ButtonRemove, ButtonTemp, BoxDraggable, Container, ButtonBox } from './DraggableArea.styles.jsx'
import downloadBox2 from '../../../../../helpers/emailsTemplates/box2/downloadBox2.js';

const DraggableArea = ({ allBlocks, updateAllBlocks, getOrder, getValue, content, dynamicContent, filesNames }) => {
  const draggableAreaRef = useRef(null);

  const handleDrop = (draggedId, targetId) => {
    const draggedIndex = allBlocks.findIndex((box) => box.id === draggedId);
    const targetIndex = allBlocks.findIndex((box) => box.id === targetId);
    const newBoxes = [...allBlocks];

    if (draggedIndex < targetIndex) {
      newBoxes.splice(targetIndex + 1, 0, newBoxes[draggedIndex]);
      newBoxes.splice(draggedIndex, 1);
    } else if (draggedIndex > targetIndex) {
      newBoxes.splice(targetIndex, 0, newBoxes[draggedIndex]);
      newBoxes.splice(draggedIndex + 1, 1);
    }

    updateAllBlocks(newBoxes);
  };

  const removeBlock = (id) => {
    const updatedBoxes = [...allBlocks];
    const index = updatedBoxes.findIndex((box) => box.id === id);
    updatedBoxes.splice(index, 1);
    updateAllBlocks(updatedBoxes);
  };

  const getBlockOrder = useCallback(() => {
    const blockElements = draggableAreaRef.current.querySelectorAll('.box');
    const blockCurrentOrder = Array.from(blockElements).map((blockElement) => blockElement.id).filter((id) => id !== '');
    getOrder(blockCurrentOrder);
  }, [getOrder]);

  const openHTMLPage = () => {
    const filesNamesToString = filesNames.map(fileName => `<a href="#">${fileName}</a>`).join(' ');

    const newTab = window.open('', '_blank');
    const insertedDynamicContent = EmailTemplate.replace('{content}', dynamicContent['main'])
      .replace('{coverImg}', dynamicContent['coverImg'])
      .replace('{footer}', dynamicContent['footer'])
      .replace('{downloadContent}', filesNamesToString ? downloadBox2 : '')
      .replace('{links}', filesNamesToString);
    newTab.document.write(insertedDynamicContent);
    newTab.document.close();
  };

  useEffect(() => {
    getBlockOrder();
  }, [content, getBlockOrder]);

  const handleLastVersion = () => {

    const savedContent = JSON.parse(localStorage.getItem('customContent'));

    const transformedData = Object.entries(savedContent).map(([name, value]) => {
      const type = name.split('_')[1];

      let component;
      switch (type) {
        case 'title':
          component = <TitleDrag getValue={getValue} name={name} savedValue={value} />;
          break;
        case 'text':
          component = <TextareaDrag getValue={getValue} name={name} savedValue={value} />;
          break;
        case 'image':
          component = <ImageDrag getValue={getValue} name={name} savedValue={value} />;
          break;
        case 'video':
          component = <VideoLinkDrag getValue={getValue} name={name} savedValue={value} />;
          break;
        case 'coverImg':
          component = <ImageDrag getValue={getValue} name={name} savedValue={value} />;
          break
        case 'footerText':
          component = <FooterDrag getValue={getValue} name={name} savedValue={value} />;
          break
        case 'footerImg':
          component = <ImageDrag getValue={getValue} name={name} savedValue={value} />;
          break
        default: break;
      }

      return {
        id: name,
        content: component
      };
    });

    updateAllBlocks(transformedData);

  };

  return (
    <Container ref={draggableAreaRef}>
      <DndProvider backend={HTML5Backend}>
        {allBlocks.map((box) => (
          <BoxDraggable
            key={box.id}
            id={box.id}
            className="box"
          >
            <DraggableBlock
              id={box.id}
              content={box.content}
              handleDrop={handleDrop}
            />
            <ButtonRemove onClick={() => removeBlock(box.id)}> <RemoveIcon /> Remover </ButtonRemove>
          </BoxDraggable>
        ))}
      </DndProvider>
      <ButtonBox>
        <ButtonTemp onClick={handleLastVersion}>
          <AutorenewIcon />
          <Typography marginLeft={'4px'}>Última versão</Typography>
        </ButtonTemp>
        <ButtonTemp onClick={openHTMLPage}>
          <RemoveRedEyeIcon />
          <Typography marginLeft={'4px'}>Preview</Typography>
        </ButtonTemp>
      </ButtonBox>
    </Container>
  );
};

export default DraggableArea;