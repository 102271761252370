import { styled } from '@mui/system';
import { Button, Box } from '@mui/material';

export const Container = styled(Box)(() => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'column'
}))

export const BoxDraggable = styled(Box)(() => ({
    marginBottom: '20px',
    zIndex: 0,
}))

export const ButtonRemove = styled(Button)(() => ({
    textTransform: "none",
    marginLeft: '3.5em',
    color: '#d41e1e',
    padding: 0,
}))

export const ButtonBox = styled(Box)(() => ({
    display: 'flex', 
    height: '100%', 
    alignItems: 'end'
}))

export const ButtonTemp = styled(Button)(() => ({
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'space-around',
    textTransform: 'none',
    height: '35px',
    width: 'fit-content'
}))



